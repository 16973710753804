import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Label
} from "reactstrap";
import { chartExample2 } from "variables/charts.js";
import { LineChartView } from "backedComponents/LineChart/LineChartView";
import { SimpleTableView } from "backedComponents/SimpleTable/SimpleTableView";
import { Enviroments } from "../enviroments/enviroments";
import HistoryOdds from "../components/HistoryOdds/HistoryOdds";
import HistoryHours from "../components/HistoryHours/HistoryHours";
import HistoryOddsPinks from "../components/HistoryOddsPinks/HistoryOddsPinks";
import SignalBot from "../components/SignalBot/SignalBot";
import SignalBotTwo from "../components/SignalBotTwo/SignalBotTwo";
import moment from "moment";
import LogRocket from "logrocket";
import InvestmentTable from "../components/InvestmentTable/InvestmentTable";

const Dashboard = () => {
  const [betHouse, setBetHouse] = useState("MINES_BET");
  const [analysisVela, setAnalysisVela] = useState(2);
  const [
    analysisHistoryResultsAviator,
    setAnalysisHistoryResultsAviator
  ] = useState([
    {
      id: 781045,
      odd: "-",
      date: "-",
      hour: "-",
      formattedDate: "-",
      betHouse: "-"
    }
  ]);
  const [
    analysisHistoryMostPositionsAndQuantity,
    setAnalysisHistoryMostPositionsAndQuantity
  ] = useState([0]);
  const [analysisHistoryByOdd, setAnalysisHistoryByOdd] = useState([0]);
  const [
    numberLastVelasFilterAnalysis,
    setNumberLastVelasFilterAnalysis
  ] = useState(10);
  const [numberVelas, setNumberVelas] = React.useState(10);
  const [analysisLoading, setAnalysisLoading] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")) || undefined;
    LogRocket.identify(localStorage.getItem("id") || "nao encontrado", {
      useId: localStorage.getItem("id") || "nao encontrado",
      email: user?.email
    });
  }, []);

  const getHistoryQuantityApi = async () => {
    const dateNow = moment(new Date()).format("YYYY-MM-DD");

    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("apiToken")}`
        }
      };
      const betHouseCustom = betHouse === "BR4BET" ? "MINES_BET" : betHouse;
      const response = await axios.get(
        `${Enviroments.API_URL_NODE}analysis-position-odd?date=${dateNow}&betHouse=${betHouseCustom}&odd=${numberVelas}`,
        headers
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
    }
  };

  const getHistoryAnalysisApi = async () => {
    const dateNow = moment(new Date()).format("YYYY-MM-DD");

    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("apiToken")}`
        }
      };

      const betHouseCustom = betHouse === "BR4BET" ? "MINES_BET" : betHouse;
      const response = await axios.get(
        `${Enviroments.API_URL_NODE}analysis-odd?date=${dateNow}&betHouse=${betHouseCustom}&odd=${numberVelas}`,
        headers
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await getHistoryAnalysisApi();
      const resQuantity = await getHistoryQuantityApi();
      setAnalysisHistoryByOdd(res);
      setAnalysisHistoryMostPositionsAndQuantity(resQuantity);
    };

    fetchData();
  }, [numberVelas, betHouse]);

  const MINES_BET = "MINES_BET";
  const BRAZUKA = "BRAZUKA";

  const handleIframeUrl = () => {
    switch (betHouse) {
      case MINES_BET:
        return "https://go.aff.br4-partners.com/6y060br7?afp=dashboard";
      case BRAZUKA:
        return "https://br4bet.com/play/8197"
    }
  };

  const handleResultsUpdated = results => {
    setAnalysisHistoryResultsAviator(results);
  };

  return (
    <div className="content">
    <Row>
        <Col xs="12" sm="8" md="3" lg="3">
          <Card>
            <CardHeader>
              <p>
                Casa selecionada:{" "}
                <h5 className="title" style={{ display: "inline" }}>
                  BR4BET
                </h5>{" "}
              </p>
            </CardHeader>
            <CardBody className="all-icons">
              <div className="d-flex">
                <Button
                  color={betHouse === "MINES_BET" ? "success" : "default"}
                  className="btn-round"
                  size="sm"
                  onClick={() => setBetHouse("MINES_BET")}
                >
                  Aviator 1 - Br4bet
                </Button>
                <Button
                  color={betHouse === "BRAZUKA" ? "success" : "default"}
                  className="btn-round"
                  size="sm"
                  onClick={() => setBetHouse("BRAZUKA")}
                >
                  Aviator 2 - Br4bet
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <HistoryOdds props={{ betHouse: betHouse }} />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <HistoryOddsPinks
            props={{ betHouse: betHouse }}
            onResultsUpdated={handleResultsUpdated}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">Cálculo de Gestão da Banca</h5>
              <h5 className="card-category">Clique em Calcular logo após em Detalhes para expandir</h5>
              <InvestmentTable />
            </CardHeader>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">BR4BET</h5>
              <p className="category">
                Pode operar diretamente pelo nosso Dashboard!
              </p>
            </CardHeader>
            <CardBody className="all-icons">
              <iframe
                height="700"
                title="Hacking Aviator"
                style={{ border: "none" }}
                width={window.screen.width}
                name="aviator"
                className="col-md-12"
                src={handleIframeUrl()}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {/**
        <Col lg="12">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">
                Gráfico de Analise de Vela:{" "}
                <strong className="text-primary">10X</strong>
              </h5>
              <LineChartView
                data={
                  chartExample2(analysisHistoryResultsAviator?.map(x => x.odd))
                    .data
                }
                options={
                  chartExample2(analysisHistoryResultsAviator?.map(x => x.odd))
                    .options
                }
                passedRef={newRef => setChartRef2(newRef)}
                totalData={analysisHistoryResultsAviator.length}
              />
            </CardHeader>
          </Card>
        </Col> */}

        <Col xs="12">
          <Card className="card-chart p-4">
            <h5 className="card-category">
              Selecione o valor da odd para ver o filtro abaixo:
            </h5>
            <div class="form-floating">
              Odd
              <select
                id="floatingSelect"
                className="form-control"
                onChange={event => setNumberVelas(event.target.value)}
                value={numberVelas}
              >
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
            </div>{" "}
          </Card>
        </Col>
        <Col lg="8">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">
                Histórico Detalhado de Analise de Vela
              </h5>
              <h5>
                Vela: <strong className="text-primary">{numberVelas}X</strong>
              </h5>
              <CardTitle tag="h3">
                <SimpleTableView
                  dataHeaders={["position", "intervalTime", "odd", "minutes"]}
                  data={analysisHistoryByOdd}
                />
                <i className="tim-icons icon-send text-primary" />{" "}
              </CardTitle>
            </CardHeader>
          </Card>
        </Col>

        <Col lg="4">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">
                Posicao que repetiu o mesmo valor da ODD:{" "}
                <strong className="text-primary">{numberVelas}X</strong>
              </h5>
              <CardTitle tag="h3">
                <SimpleTableView
                  dataHeaders={["posicao", "quantidade", "porcentagem"]}
                  data={analysisHistoryMostPositionsAndQuantity}
                />
              </CardTitle>
            </CardHeader>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <HistoryHours props={{ betHouse: betHouse }} />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
